<template>
    <v-container fluid>
          <!-- editor -->
            <editor :currencies="currencies" :initial="dataItem" @data="save" :pageMode="pageMode"></editor>
          <!-- end -->
    </v-container>
</template>

<script>
import Editor from '../_components/editor.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Editor
  },
  computed: {
    ...mapGetters(['user'])
  },
  data: () => ({
    dataItem: {},
    currencies: [],
    pageMode: "create"
  }),
  methods: {
    save(data) {
      data.ListNum = 1;
      const url = "/bp_masterdata";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if(res.ResultCode == 1200) {
            self.$router.push("/business-partners/item-master-data");
          }
          // self.$refs.snackbar.show("Item group created", "green");
          
        })
        .catch(err => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then(res => {
          self.currencies = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getCurrencies();
  }
};
</script>